(function ($) {

    $( document ).ready(function(){
        var coords = hotel_data.hotel_coords.split(',');
        var weather_api_key = hotel_data.weather_widget_api_key;
        var weather_url = "https://api.decms.eu/weather/?lat="+coords[0]+"&lng="+coords[1]+"&key=" + weather_api_key
        var lang = $('#footer_weather').data('lang');
        var weather_lang = 'en-GB'
        if ( 'en' != lang ) {
            weather_url += "&lang="+lang
            weather_lang = lang
        }

        var ajax = new XMLHttpRequest();
        ajax.onload = weather_ajax_call;
        ajax.onerror = ajax_error;
        ajax.open("GET", weather_url, true);
        ajax.send();

        function weather_ajax_call() {
            if (this.status == 200) { // request succeeded
                var data = JSON.parse(this.responseText);
                var today = new Date(data.currently.time * 1000);
                var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
                var timezone = isIE11 ? 'UTC' : data.timezone;
                data.date = today.toLocaleDateString( weather_lang, {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    timeZone: timezone
                } );
                data.time = new Date(today).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    timeZone: timezone
                }) + (isIE11 ? ' UTC' : '');
                data.temperature = Math.trunc(data.currently.temperature);
                data.summary = data.currently.summary;
                data.icon = data.currently.icon;
                data.apparentTemperature = Math.trunc(data.currently.apparentTemperature);
                data.temperatureMin = Math.trunc(data.daily.data[0].temperatureMin);
                data.temperatureMax = Math.trunc(data.daily.data[0].temperatureMax);
                data.temperature = Math.trunc(data.currently.temperature);

                switch ( data.currently.icon){
                    case 'clear-day':
                        data.icon = 'fb_weather_sun';
                        break;
                    case 'clear-night':
                        data.icon = 'fb_weather_moon';
                        break;
                    case 'rain':
                        data.icon = 'fb_weather_cloud_rain';
                        break;
                    case 'snow':
                        data.icon = 'fb_weather_cloud_snow';
                        break;
                    case 'sleet':
                        data.icon = 'fb_weather_mixed_rain_snow';
                        break;
                    case 'wind':
                        data.icon = 'fb_weather_wind';
                        break;
                    case 'fog':
                        data.icon = 'fb_weather_fog';
                        break;
                    case 'cloudy':
                        data.icon = 'fb_weather_cloudy';
                        break;
                    case 'partly-cloudy-day':
                        data.icon = 'fb_weather_partly_cloudy_day';
                        break;
                    case 'partly-cloudy-night':
                        data.icon = 'fb_weather_partly_cloudy_night';
                        break;

                }

                var template_id = '#footer_weather_template';
                var template = $(template_id).text();
                var html = Mustache.render(template, data);
                $('#footer_weather').html(html);

            } else {
                console.log( 'error with weather' );
            }
        }
        function ajax_error(e) {
            console.log(this);
            console.error(e);
            // do something with this.status, this.statusText
        }
    });


})(jQuery);